<template>
  <div>
    <a-spin :spinning="loading" size="large">&nbsp;</a-spin>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'QrcLoginCallback',
  data() {
    return {
      loading: true
    }
  },
  computed: {
    code() {
      if (this.$route.query.code) {
        return this.$route.query.code
      }

      return ''
    }
  },
  created() {
    this.doLogin()
  },
  methods: {
    ...mapActions(['QrcLogin']),

    doLogin() {
      if (this.code) {
        this.QrcLogin(Object.assign({ code: this.code })).then((res) => {
          this.$router.push({ path: this.redirect || '/' }).catch(() => {
          })
        }).catch(() => {
          location.href = '/'
        })
      } else {
        location.href = '/'
      }
    }
  }
}
</script>
